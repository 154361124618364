import { Layout } from '../components';
import { getComponent } from '../lib/ComponentFactory';
import { getPageProps } from '../lib/propResolver';
import { defaultLocale } from '../siteConfig';

/**
 * @param {object} [props] component props
 * @param {object} [props.pageData] page data from contentful
 * @param {object} [props.globalData] global data from contentful
 * @param {string} [props.locale] current locale
 * @param {object} [props.localesData] data from locale
 * @param {string} [props.className] classname to be used in the layout component
 * @returns {JSX.Element} react component
 */
function custom404({ pageData, globalData, locale, localesData, className }) {
  pageData.fields.errorPage = true;
  return (
    pageData && (
      <Layout
        title={pageData.fields.title || pageData.fields.productName}
        brandSlug={`/${pageData.fields.brand.fields.slug}`}
        seoData={pageData.fields.seoData}
        globalData={globalData}
        className={className}
        locale={locale}
        localesData={localesData}
        error={true}>
        {getComponent(pageData.sys.contentType.sys.id, { fields: pageData.fields })}
      </Layout>
    )
  );
}

/**
 * @returns {Promise} error page data
 */
export async function getStaticProps() {
  const brand = process.env.BRAND;
  return await getPageProps({ locale: defaultLocale, slug: 404 }, brand);
}

export default custom404;
